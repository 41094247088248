import { tns } from 'tiny-slider/src/tiny-slider';


document.getElementById("hideAll").style.opacity = 1;

const slider = tns({
  container: '.main-header-slider',
  items: 1,
  animateIn: 'jello',
  nav: false,
  controls: false,
  gutter: 2,
  preventScrollOnTouch: 'auto',
  mouseDrag: true,
  animateDelay: 10000,
  autoplayButtonOutput: false,
  slideBy: 'page',
  autoplay: true,
  swipeAngle: false,

});
